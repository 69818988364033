/* Set All Printing Document Color to Black */
@media print {
  .printable * {
    color: black;
    border-color: black;
  }
}

/* Adjust Font Size for ~58mm width Paper Size */
@media print and (max-width: 220px) {
  html {
    font-size: 12px;
  }
}

/* Adjust Font Size for ~58mm width Paper Size */
@media print and (min-width: 221px) and (max-width: 303px) {
  html {
    font-size: 14px;
  }
}
