@font-face {
  font-family: 'Inter-Regular';
  src: url('/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('/fonts/Inter/Inter-SemiBold.ttf');
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('/fonts/Inter/Inter-Bold.ttf');
}
